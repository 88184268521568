import { EMPTY_EDI_ITEM_CODE_MAPPING_MODEL } from "presentation/model/EdiItemCodeMapping/EdiItemCodeMappingMaintenanceModel";
import { useState } from "react";
import { createContainer } from "react-tracked";

const {
    Provider: EdiItemCodeMappingMaintenanceProvider,
    useTracked: useEdiItemCodeMappingMaintenanceTracked
} = createContainer(() => useState(EMPTY_EDI_ITEM_CODE_MAPPING_MODEL), {concurrentMode: true});
export { EdiItemCodeMappingMaintenanceProvider, useEdiItemCodeMappingMaintenanceTracked };

