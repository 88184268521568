export const ediItemCodeMappingMaintenanceRequiredFieldList: string[] = [
    'ediItemCodeMapping', 
];

export const EdiItemCodeMappingMaintenanceConstant = { 
    CATEGORY: "EDI",   
    TITLE: "EDI Item Code",
    EDI_ITEM_CODE_MAPPING: "EDI Item Code Mapping",
    PRIORITY: "Priority",
    BILL_TO_COMPANY: "Bill To Company",
    TARIFF_TYPE: "Tariff Type",
    TARIFF_CODE: "Tariff Code",
    TARIFF_CODE_DESC: "Tariff Code Desc",
    ITEM_CODE: "Item Code",
    ACTIVE_IND: "Active Ind.",
    DISPLAY_TARIFF_EXTENSION: "Display Tariff Extension",
    CONTAINER_SIZE: "Container Size",
    ITEM_CODE_TYPE: "Item Code Type",
    DESC: "Desc.",

}
