import { CompanyRepoImpl } from "domain/repository/Company/CompanyRepoImpl";
import { EdiItemCodeMappingRepoImpl } from "domain/repository/EdiItemCodeMapping/EdiItemCodeMappingRepoImpl";
import { ParameterDetailRepoImpl } from "domain/repository/parameter/ParameterDetailRepoImpl";
import { StandardTariffCodeRepoImpl } from "domain/repository/TariffCode/StandardTariffCodeRepoImpl";
import { TariffTypeRepoImpl } from "domain/repository/TariffCode/TariffTypeRepoImpl";
import { useEdiItemCodeMappingMaintenanceTracked } from "presentation/store/EdiItemCodeMapping/EdiItemCodeMappingMaintenanceProvider";
import { EdiItemCodeMappingMaintenanceVM } from "presentation/viewModel/EdiItemCodeMapping/EdiItemCodeMappingMaintenanceVM";
import { useMemo } from "react";

export const useEdiItemCodeMappingMaintenanceVM = () => {
    const [, setEdiItemCodeMappingMaintenanceState] = useEdiItemCodeMappingMaintenanceTracked();
    const ediItemCodeMappingMainVM = useMemo(() =>
        EdiItemCodeMappingMaintenanceVM({
            dispatch: [setEdiItemCodeMappingMaintenanceState],
            repo: EdiItemCodeMappingRepoImpl(),
            parameterDetailRepo: ParameterDetailRepoImpl(),
            companyRepo: CompanyRepoImpl(),
            standardTariffCodeRepo: StandardTariffCodeRepoImpl(),
            tariffTypeRepo: TariffTypeRepoImpl(),
        }), [setEdiItemCodeMappingMaintenanceState])

    return ediItemCodeMappingMainVM
}

