import * as yup from 'yup';


export const commonEdiItemCodeMappingMaintenanceValidation: { [x: string]: any; } = {
  tariffType: yup.string().required("Missing"),
  tariffCode: yup.string().required("Missing"),
  ediItemCode: yup.string().required("Missing"),
  itemCodeType: yup.string().required("Missing"),
};

export const createEdiItemCodeMappingMaintenanceValidation: { [x: string]: any; } = {
  ...commonEdiItemCodeMappingMaintenanceValidation,
};

export const createEdiItemCodeMappingMaintenanceValidationSchema = yup.object().shape(createEdiItemCodeMappingMaintenanceValidation);

