import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { EdiItemCodeMappingMaintenanceProvider } from "presentation/store/EdiItemCodeMapping/EdiItemCodeMappingMaintenanceProvider";
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper";
import { MessageBarWrapper } from "presentation/view/components/MessageBarWrapper";
import EdiItemCodeMappingMasterView from "presentation/view/section/EdiItemCodeMapping/EdiItemCodeMappingMasterView";
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled";

const EdiItemCodeMappingMaintenanceCont = () => {
    return <ANAInfoWrapper permission={Permission.EDI_ITEM_CODE_MAPPING_MAINTENANCE}>
        <MessageBarWrapper>
            <EdiItemCodeMappingMaintenanceProvider>
                <GridStyles />
                <EdiItemCodeMappingMasterView />
            </EdiItemCodeMappingMaintenanceProvider>
        </MessageBarWrapper>
    </ANAInfoWrapper>
}

export default EdiItemCodeMappingMaintenanceCont;