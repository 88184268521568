import { ResponseEntity } from "domain/entity/Common/ResponseEntity";
import { EdiItemCodeMappingEntity } from "domain/entity/EdiItemCodeMapping/EdiItemCodeMappingEntity";
import { axiosDeleteData, axiosGetData, axiosPostData, axiosPutData } from "../axios/AxiosBasicImpl";
import referenceAxiosInstance from "../axios/referenceAxiosInstance";
import { EdiItemCodeMappingRepository } from "./EdiItemCodeMappingRepo";


export const EdiItemCodeMappingRepoImpl = (): EdiItemCodeMappingRepository => {
    const url = '/v1/ediItemCodeMapping';

    const getEntities = async (): Promise<EdiItemCodeMappingEntity[]> => {
        return await axiosGetData(referenceAxiosInstance, url, []).then(res => {
            return res.data
        }).catch(err => {
            return [];
        });

    }

    const getEntityByKey = async (key: string): Promise<EdiItemCodeMappingEntity> => {
        return await axiosGetData(referenceAxiosInstance, `${url}/${key}`, []).then(res => {
            return res.data;
        }).catch(err => {
            return null;
        });
    }

    const createEntity = async (newData: EdiItemCodeMappingEntity): Promise<ResponseEntity> => {
        return await axiosPostData(referenceAxiosInstance, `${url}`, newData);
    }

    const updateEntity = async (updatedData: EdiItemCodeMappingEntity): Promise<ResponseEntity> => {
        return await axiosPutData(referenceAxiosInstance, `${url}`, updatedData);
    }

    const deleteEntity = async (key: string): Promise<ResponseEntity> => {
        return await axiosDeleteData(referenceAxiosInstance, `${url}`, key);
    }

    const checkDuplicated = async (entity: EdiItemCodeMappingEntity): Promise<ResponseEntity> => {
        const checkDuplicatedUrl = '/v1/ediItemCodeMappingCheckDuplicated'
        return await axiosPostData(referenceAxiosInstance, `${checkDuplicatedUrl}`, entity);
    }

    const onApply = async (rows: EdiItemCodeMappingEntity[]): Promise<ResponseEntity> => {
        const applyUrl = "/v1/ediItemCodeMappingEntityApply"
        return await axiosPostData(referenceAxiosInstance, `${applyUrl}`, rows);
    }

    return {
        getEntities: getEntities,
        getEntityByKey: getEntityByKey,
        createEntity: createEntity,
        updateEntity: updateEntity,
        deleteEntity: deleteEntity,
        checkDuplicated: checkDuplicated,
        onApply: onApply,
    }
}