import { DataMappingEntity } from "../Common/DataMappingEntity"

export interface EdiItemCodeMappingEntity extends DataMappingEntity {
    id: number,
    billToCompany: string | null,
    tariffType: string | null,
    tariffCode: string | null,
    tariffDesc: string | null,
    cntrSize: string | null,
    itemCodeType: string | null,
    ediItemCode: string,
    displayTariffExtension: string | null,
    recommendation23Desc: string | null,

    [key: string]: string | number | Date | null | undefined
}

export const EMPTY_EDI_ITEM_CODE_MAPPING_ENTITY: EdiItemCodeMappingEntity = {
    id: 0,
    billToCompany: null,
    tariffType: null,
    tariffCode: null,
    tariffDesc: null,
    cntrSize: null,
    itemCodeType: "EDI Item Code",
    ediItemCode: "",
    displayTariffExtension: "Y",
    recommendation23Desc: null,
    priority: 0,
    effectiveDate: null,
    expiryDate: null,
    confirmedDate: null,
    mappingType: "EdiItemCodeMappingEntity",
    activeInd: "Y",
}

